// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-journey-index-js": () => import("./../../../src/pages/journey/index.js" /* webpackChunkName: "component---src-pages-journey-index-js" */),
  "component---src-pages-photos-index-js": () => import("./../../../src/pages/photos/index.js" /* webpackChunkName: "component---src-pages-photos-index-js" */),
  "component---src-pages-thoughts-index-js": () => import("./../../../src/pages/thoughts/index.js" /* webpackChunkName: "component---src-pages-thoughts-index-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-journey-post-js": () => import("./../../../src/templates/journey-post.js" /* webpackChunkName: "component---src-templates-journey-post-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-photo-post-js": () => import("./../../../src/templates/photo-post.js" /* webpackChunkName: "component---src-templates-photo-post-js" */),
  "component---src-templates-thoughts-post-js": () => import("./../../../src/templates/thoughts-post.js" /* webpackChunkName: "component---src-templates-thoughts-post-js" */)
}

