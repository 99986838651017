import React, { createContext, useReducer } from "react";
import ItemsReducer from "./ItemsReducer";

const initialState = {
  canvas: false,
  pageType: "index"
};

const Store = ({ children }) => {
  const [state, dispatch] = useReducer(ItemsReducer, initialState);

  return (
    <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
  );
};

export const Context = createContext(initialState);
export default Store;
