const ItemsReducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_CANVAS":
      return {
        ...state,
        canvas: !state.canvas
      };
    case "PAGE_TYPE":
      if (state.pageType === action.payload) {
        return state;
      }

      return {
        ...state,
        pageType: action.payload
      };
    default:
      return state;
  }
};

export default ItemsReducer;
